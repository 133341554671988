<template>
    <b-row>
        <b-col md="4">
            <b-card class="text-center">
                <b-avatar
                size="150"
                variant="light-primary"
                :src="photo"
                class="badge-minimal"
                badge-variant="success"
                />
                <b-card-text class="font-weight-bolder mt-20 mb-auto"></b-card-text>
                <b-badge variant="light-primary text-capitalize">{{ form.name }}</b-badge>
                <b-row class="mt-20" v-if="permissions.includes('company-update-create')">
                    <b-col>
                        <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-primary"
                            block
                            v-b-modal.modal-upload-logo
                            >
                            Upload Company Logo
                        </b-button>

                        <!-- modal upload logo -->
                        <b-modal
                        id="modal-upload-logo"
                        title="Upload Logo"
                        ok-title="Change Logo"
                        cancel-variant="outline-secondary"
                        hide-footer
                        >
                            <validation-observer ref="uploadCompanyLogo">
                            <b-form ref="form" @submit.prevent>
                                <b-form-group
                                :state="errors"
                                label="Logo"
                                label-for="logo"
                                invalid-feedback="Logo is required"
                                >
                                    <validation-provider
                                    #default="{ errors }"
                                    name="logo"
                                    rules="required"
                                    >
                                        <image-uploader
                                        :preview="true"
                                        :className="[
                                            'fileinput',
                                            { 'fileinput--loaded': hasImage },
                                        ]"
                                        :debug="0"
                                        :autoRotate="true"
                                        accept="image/*"
                                        doNotResize="['gif', 'svg']"
                                        outputFormat="string"
                                        @input="setImage"
                                        ></image-uploader>

                                        <!-- <b-button
                                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                            variant="outline-primary"
                                            block
                                            v-b-modal.modal-upload-photo
                                            for="#test"
                                            >
                                            Browse
                                        </b-button> -->
                                        <small class="text-danger">{{ errors[0] || errMessageImage }}</small>
                                    </validation-provider>
                                </b-form-group>

                                <b-row>
                                    <b-col>
                                        <b-button
                                            class="mt-1"
                                            type="submit"
                                            variant="primary"
                                            block
                                            @click="uploadCompanyLogo()"
                                        >
                                            Upload Photo
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                            </validation-observer>
                        </b-modal>
                        <!-- end modal upload logo -->
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
        <b-col>
            <b-card title="Details Company">
                <b-form @submit.prevent>
                    <b-row>
                        <b-col cols="12">
                            <b-form-group
                            label="Name"
                            label-for="name"
                            >
                            <b-form-input
                                id="name"
                                placeholder="Name"
                                v-model="form.name"
                                required
                            />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group
                            label="Address"
                            label-for="address"
                            >
                            <b-form-textarea
                                id="address"
                                placeholder="Address"
                                v-model="form.address"
                                required
                            />
                            </b-form-group>
                        </b-col>
                        <b-col cols="4">
                            <b-form-group
                            label="Village"
                            label-for="village"
                            >
                            <b-form-input
                                id="village"
                                placeholder="Village"
                                v-model="form.village"
                                required
                            />
                            </b-form-group>
                        </b-col>
                        <b-col cols="4">
                            <b-form-group
                            label="District"
                            label-for="district"
                            >
                            <b-form-input
                                id="district"
                                placeholder="District"
                                v-model="form.district"
                                required
                            />
                            </b-form-group>
                        </b-col>
                        <b-col cols="4">
                            <b-form-group
                            label="City"
                            label-for="city"
                            >
                            <b-form-input
                                id="city"
                                placeholder="City"
                                v-model="form.city"
                                required
                            />
                            </b-form-group>
                        </b-col>
                        <b-col cols="4">
                            <b-form-group
                            label="Province"
                            label-for="province"
                            >
                            <b-form-input
                                id="province"
                                placeholder="Province"
                                v-model="form.province"
                                required
                            />
                            </b-form-group>
                        </b-col>
                        <b-col cols="4">
                            <b-form-group
                            label="Country"
                            label-for="country"
                            >
                            <b-form-input
                                id="country"
                                placeholder="Country"
                                v-model="form.country"
                                required
                            />
                            </b-form-group>
                        </b-col>
                        <b-col cols="4">
                            <b-form-group
                            label="Postal Code"
                            label-for="postal_code"
                            >
                            <b-form-input
                                id="postal_code"
                                placeholder="Postal Code"
                                v-model="form.postal_code"
                                required
                            />
                            </b-form-group>
                        </b-col>
                        <b-col cols="4">
                            <b-form-group
                            label="Phone"
                            label-for="phone"
                            >
                            <b-form-input
                                id="phone"
                                placeholder="Phone"
                                v-model="form.phone"
                                required
                            />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row v-if="permissions.includes('company-update-create')">
                        <!-- Update -->
                        <b-col md="12">
                            <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="submit"
                            variant="success"
                            @click="update()"
                            block
                            >
                            Update
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { BFormTextarea, BAvatar, BImg, BFormFile, BFormGroup, BFormInput, BForm, BModal, BButton, BBadge , BRow, BCol, BCard, BCardText, BLink } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import ImageUploader from 'vue-image-upload-resize'
    import { $themeConfig } from "@themeConfig"
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    export default {
        components: {
            ToastificationContent,
            ImageUploader,
            BFormTextarea,
            BAvatar,
            BImg,
            ValidationProvider,
            ValidationObserver,
            BFormGroup,
            BFormInput,
            BForm,
            BFormFile,
            BModal,
            BButton,
            BBadge,
            BRow,
            BCol,
            BCard,
            BCardText,
            BLink
        },
        directives: {
            Ripple,
        },
        data() {
            return {
                form: {
                    name: '',
                    address: '',
                    village: '',
                    district: '',
                    city: '',
                    province: '',
                    country: '',
                    postal_code: null,
                    phone: null
                },
                permissions: [],
                hasImage: '',
                image: '',
                photo: '',
                errMessage: '',
                errMessageImage: '',
                errors: ''
            }
        },
        setup() {
            // App Name
            const { baseUrl, apiUrl } = $themeConfig.app;
            return {
                baseUrl,
                apiUrl
            };
        },
        mounted() {
            this.$http
            .get('company')
            .then((response) => {
                // console.log(response.data.data)
                this.form.name = response.data.data.name
                this.form.address = response.data.data.address
                this.form.village = response.data.data.village
                this.form.district = response.data.data.district
                this.form.city = response.data.data.city
                this.form.province = response.data.data.province
                this.form.country = response.data.data.country
                this.form.postal_code = response.data.data.postal_code
                this.form.phone = response.data.data.phone
                const { baseUrl } = $themeConfig.app
                this.photo = baseUrl
                this.photo += 'storage/company/'
                if(response.data.data.image === null){
                    response.data.data.image = 'nophoto.png'
                    this.photo += response.data.data.image
                } else {
                    this.photo += response.data.data.image
                }
            }).catch((err) => {
                console.log(err.response)
            });
        },
        created() {
            this.getAllPermissions()
        },
        methods: {
            getAllPermissions() {
                this.permissions = sessionStorage.getItem('permissions')
            },
            setImage: function (file) {
                this.hasImage = true
                this.image = file
            },
            uploadCompanyLogo() {
                this.$http
                .post("company/image", {
                    'image': this.image
                })
                .then((response) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Notification',
                            icon: 'BellIcon',
                            text: 'Success update Company Image',
                            variant: 'success',
                        },
                    })
                    location.href = "/company"
                    // console.log(response.data)
                }).catch((errors) => {
                    this.errMessageImage = errors.response.data.message
                    console.log(errors.response)
                });
            },
            update() {
                this.$http
                .post("company/update", {
                    'name': this.form.name,
                    'address': this.form.address,
                    'village': this.form.village,
                    'district': this.form.district,
                    'city': this.form.city,
                    'province': this.form.province,
                    'country': this.form.country,
                    'postal_code': this.form.postal_code,
                    'phone': this.form.phone
                })
                .then((response) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Notification',
                            icon: 'BellIcon',
                            text: 'Success update detail Company',
                            variant: 'success',
                        },
                    })
                    location.href = "/company"
                    // console.log(response.data)
                }).catch((errors) => {
                    this.errMessage = errors.response.data.message
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Notification',
                            icon: 'BellIcon',
                            text: this.errMessage,
                            variant: 'warning',
                        },
                    })
                    console.log(errors.response)
                });
            },
        },
    }
</script>

<style>
    .mt-20 {
        margin-top: 20px;
    }
</style>